@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('assets/fonts/font.css');

@layer base {
    html {
        /* font-family: 'Comic Sans MS', Lato, Proxima Nova, system-ui, sans-serif; */
        font-family: Lato, Proxima Nova, system-ui, sans-serif;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    button {
        @apply focus:outline-none;
    }
    input {
        @apply focus:outline-none;
    }
    a {
        @apply outline-none;
    }
}

@layer utilities {
    .table {
        @apply border-collapse;
    }

    .table th {
        @apply bg-red-600 text-red-50;
    }

    .table th, 
    .table td {
        @apply text-sm px-4 py-3;
    }
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 250px;
    height: 250px;
    border: 5p solid red;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}